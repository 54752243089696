
export const SendRequestAccessEmail = (report: string, resourceLink: string) => {

    const subject = encodeURIComponent('Regarding Dashboard Access');
    const body = encodeURIComponent(
      `Dear Admin,\n\n` + 
      `Please provide access to the following report:\n\n  ${report} \n\n ${resourceLink}.\n\n\n` +
      `Thanks,\n`
    );
    
    const recipients = encodeURIComponent('dl_PMSAnalytics_Viz@philips.com, pmscoe@philips.com');
    const ccRecipients = encodeURIComponent('sambath.s@philips.com,shankar.viswanathan@philips.com')
    const mailtoLink = `mailto:${recipients}?cc=${ccRecipients}&subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
};

export const SendEmail = () => {
  const recipients = encodeURIComponent('pmscoe@philips.com');
  const mailtoLink = `mailto:${recipients}`;
  window.location.href = mailtoLink;
};


