/**Imports of React libraries */
import React, { useEffect, useState } from "react";

// import { useSelector } from "react-redux";

// Import of sidebar component
import ProSidebarMenu from "./sidebar/sidebar";

// Import Pro sidebar
import "react-pro-sidebar/dist/css/styles.css";
import Header from "./Header";
import { HamburgerIcon } from "../reusableComponents/svgIcons";
import Footer from "./footer/footer";
import { getPermissions } from "../../services/user.service";
import { useUserContext } from "../pages/authentication/userContext";

interface Props {
  children: React.ReactNode;
}

interface Resource {
  id: number;
  resourceName: string;
  resourceDescription: string;
  resourceTypeId: 2;
  likesCount: 0;
  active: boolean;
  favorite: boolean;
  like: boolean;
}

export interface Permissions {
  id: number;
  resource: Resource;
  active: boolean;
}

interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  permissions: Permissions[];
  role: string;
  active: boolean;
}

const Layout = ({ children }: Props) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [user, setUser] = useState<User>();
  const [userPermissions, setUserPermissions] = useState<Permissions[]>([]);
  const [resources, setResources] = useState<string[]>(['Home', 'My Collections']);
  const { userId } = useUserContext();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isDefaultUser, setIsDefaultUser] = useState<boolean>(false);

console.log(userId);

  const refreshLayout = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };


  const fetchPermissions = (id: number) => {
    getPermissions(id)
      .then(function (response) {
        if (response.status === 200) {
          console.log(response.data);
          setUser(response.data);
          setUserPermissions(response.data.permissions);
          isAdminOrDefaultUserRole(response.data.role);
          getResources(response.data.permissions);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const isAdminOrDefaultUserRole = (role: string)  => {
    console.log("role is ", role);
    if (role === "Admin") setIsAdmin(true);
    console.log(isAdmin);
    if (role === "Default") setIsDefaultUser(true);
    console.log("default user ", isDefaultUser);
  }

  const getResources = (permissions: Permissions[]) => {
    if (!isAdmin) {
    // permissions.map((data, index) => {
      // if (data.resource.resourceName === 'Analytics & Trending Dashboard')  resources.push('My Collections')
      // resources.push(data.resource.resourceName)
  // })
  resources.push("Analytics & Trending Dashboard");
  resources.push("Complaint Code Management")
  resources.push("Apps & Tools");
  resources.push("Interactive Analysis Zone");
}
    console.log(resources);
  }
  return (
    <>
      <div className={`mainApp ${isMenuOpened ? "menuActive" : ""}`}>
        {/* <button className="hamburger" onClick={() => setIsMenuOpened(!isMenuOpened)}><HamburgerIcon /></button> */}
        <aside className="sidebarAside">
          <ProSidebarMenu isOpen={false} activeSubMenu={""} allowedResources={resources} isAdmin={isAdmin} isDefaultUser={isDefaultUser} />
        </aside>
        <div className="mainPages">
          <Header refreshLayout={refreshLayout} onSignInSuccess={(id) => fetchPermissions(id)}/>
          <div className="p-3 pb-1">{children}</div>
          {/* <Footer/> */}
        </div>
      </div>
    </>
  );
}

export default Layout;
