import { Avatar, CardContent, CardHeader, Typography } from "@mui/material"
import Card from "@mui/material/Card"
import background from "../../../assets/images/philips_ai.jpg"
import haystack from "../../../assets/images/haystack.png"
import { openLink } from "../../reusableComponents/Utilities";
import { useUserContext } from "../authentication/userContext";
import { useEffect } from "react";

const handleLinkClick = (url: string) => {
  window.open(url, '_blank', openLink());
};

const AI = () => {
  const { userId } = useUserContext();

  
  useEffect(() => {

    if (userId === null || userId === 0) {
      
      window.location.href = '/';
    } 
  }, [])

  const dataLinks = [

    {
      name: "Emerging Issues and Complaint Analysis",
      link: "https://philips-haystack.palantirfoundry.com/workspace/module/view/latest/ri.workshop.main.module.df16f971-eb50-4601-bc2b-49092cf89ce7"
    },
    // {
    //   name: "Complaint Clustering",
    //   link: "https://philips-haystack.palantirfoundry.com/workspace/module/view/latest/ri.workshop.main.module.25a3f0ea-7735-44f6-bf13-06389689dc8e"
    // },
    // {
    //   name: "Investigation Exploration",
    //   link: "https://philips-haystack.palantirfoundry.com/workspace/module/view/latest/ri.workshop.main.module.25a3f0ea-7735-44f6-bf13-06389689dc8e"
    // },
    // {
    //   name: "Outlier Exploration",
    //   link: "https://philips-haystack.palantirfoundry.com/workspace/module/view/latest/ri.workshop.main.module.25a3f0ea-7735-44f6-bf13-06389689dc8e"
    // },
    {
      name: "Complaint Signal Kanban",
      link: "https://philips-haystack.palantirfoundry.com/workspace/module/view/latest/ri.workshop.main.module.443628c4-a389-49ae-92b9-a259a3290eb0"
    }
  ]

  return (
    <section>
      <h1 className='page-header mb-3'>Interactive Analysis  Zone</h1>
      <div className="parentContainer">
        <img className="full-backgroundImage" src={background} alt="Background" />
        <Card
          style={{
            position: 'absolute',
            bottom: '70px',
            left: '25px',
            padding:'10px',
            background: 'rgba(0, 0, 0, 0)',
            color: '#f1f1f1',
            
          }}  >

<CardHeader
          avatar={
            
              <Avatar sx={{
                radius: 50, width: 30, height: 30,
                marginBottom:'10px'
              }} aria-label="report"> 
                <img  src={haystack}/>
              </Avatar>
            
          }
          title={ <Typography sx={{ fontSize: '20px', color: '#f0ad42', fontWeight: 800, marginBottom:'10px' }}><b>HAYSTACK</b></Typography>}
          
          sx={{
            '& .MuiCardHeader-title': {
              marginBottom: '-8px', 
            },
            marginBottom: '-15px',
            // marginTop: '-5px'
          }}
        />



           {dataLinks.map((data, index) => (
            <div key={index} className=' d-flex align-items-center card-height'>
              <CardContent>

              <a href="#" onClick={() => handleLinkClick(data.link)} rel="noopener noreferrer" >{data.name}</a>
              </CardContent>
            </div>
          ))} 
          {/* <div  className=' d-flex align-items-center mt-4'>

          <ul className="list-unstyled">
          <li>Emerging Issues </li>
          <li>Complaint Clustering </li>
          <li>Investigation Exploration</li>
          <li>Outlier Exploration</li>  
          
          
          <li><div className="text-center"><a href="#" className="shake-anchor" onClick={handleLinkClick} >Click Here</a></div></li>
          </ul>
          </div> */}
        </Card>
      </div>
    </section>
  )
}


export default AI