import http from "../http-common";


const dataService = "/dataapi"


export const getDataProducts = () => {
    return http.get(dataService + "/data/products");
}

