import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';
const useStyles = makeStyles({
    cookieAlert: {
        "& .MuiAlert-icon": {
            fontSize: 13,
        }
    }
});
const CustomAlert = () => {
    const classes = useStyles();


    return (
        <Alert className={classes.cookieAlert} style={{ fontSize: '9px', width: "80%", padding: "0.1px", fontWeight: 600 }} severity='error'>
            {/* <AlertTitle>Danger!</AlertTitle> */}
            Please Enter Valid Catalog Item Id</Alert>
    )
}

export default CustomAlert;