import { createContext, useContext, ReactNode, useEffect, useState } from 'react';


const UserContext = createContext<{ userId: number | 0, setUserId: React.Dispatch<React.SetStateAction<number | 0>> }>({
  userId: 0,
  setUserId: () => {}
});


export function useUserContext() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
}

export function UserProvider({ children }: { children: ReactNode }) {
  const [userId, setUserId] = useState<number | 0>(() => {
    const storedUserId = localStorage.getItem('userId');
    return storedUserId !== null ? parseInt(storedUserId) : 0;
  });


  // Update local storage whenever userId changes
  useEffect(() => {
    localStorage.setItem('userId', userId !== null ? userId.toString() : String(0));
  }, [userId]);

  return (
    <UserContext.Provider value={{ userId, setUserId }}>
      {children}
    </UserContext.Provider>
  );
}

