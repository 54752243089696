import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { purple } from '@mui/material/colors';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import FavoriteIcon from '@mui/icons-material/Favorite';
import wikiImage from '../../../assets/images/wiki.png';
import background from '../../../assets/images/PhilipsAzurion.jpg';
import { CircularProgress, Grid, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useUserContext } from '../authentication/userContext';
import { getResources, setUnsetUserFavorite, setUnsetUserLike } from '../../../services/user.service';
import { CardData } from './CardData';
import { openLink } from '../../reusableComponents/Utilities';
import CustomActionsColumn from './customActionsColumn';



const useStyles = makeStyles({
  media: {
    cursor: 'pointer',
  },
});




const SafetyPerformanceInsightComponent = () => {
  const { userId } = useUserContext();
  const [cardData, setCardData] = useState<CardData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleLinkClick = (url: string) => {
    window.open(url, '_blank', openLink());
  };

  const columns: GridColDef[] = [

    {
      field: 'resourceId', headerName: 'Id', flex: 0.3, headerClassName: 'header', renderCell : (params) => {
        return (
          <div style={{fontSize: '10px'}}> {params.row.resourceId} </div>
        );
      }
    },
    {
      field: 'resourceDescription', headerName: 'Report Name', flex: 1, headerClassName: 'header', headerAlign: 'center', sortable: false,
      renderCell: (params) =>
        <div > <Avatar sx={{
          width: 20, height: 20,
          fontSize: '0.4rem', bgcolor: purple[800], marginTop: '3px'
        }} aria-label="report">{params.row.resourceName}</Avatar>
          <div style={{ fontSize: '12px', marginLeft: '30px', marginTop: '-33px' }}>
            <a href='#' onClick={() => handleLinkClick(params.row.resourceLink)}>
              <Tooltip title='Click Here To View Reports'>
                {params.row.resourceDescription}
              </Tooltip>
            </a>
          </div>
        </div>
    },
    {
      field: 'content', headerName: 'Report Description', flex: 2.4, minWidth: 200, headerClassName: 'header', headerAlign: 'center', sortable: false, 
      renderCell: (params) => {
        return (
          // <span style={{ color: 'red' }}>{params.colDef.headerName}</span>
          <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflow: 'visible', lineHeight: '1.5', fontSize: '11px' }}>{params.value}</div>
        );
      }
    },
    { field: 'actions', headerName: 'Actions', flex: 0.6, headerClassName: 'header', headerAlign: 'center',   sortable: false, 
    disableColumnMenu: true,
    renderCell: (params: any) => (
      
      <CustomActionsColumn
        row={params.row}
        handleLikeClick={handleLikeClick}
        handleAddCollectionsClick={handleAddCollectionsClick}
        handleLinkClick={handleLinkClick}
      />
    )
    }
  ];

  cardData.forEach((row, index) => {
    row.resourceId = index + 1;
  });

  const fetchData = async () => {
    getResources("Safety and Performance Insights", userId, 1, true, "performance")
      .then(function (response) {
        if (response.status === 200) {
          setCardData(response.data);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  }

  useEffect(() => {

    if (userId === null || userId === 0) {

      window.location.href = '/';
    } else {
      setIsLoading(true);
      fetchData();
    }
  }, [])

  const classes = useStyles();


  const handleLikeClick = (id: number) => {

    setUnsetUserLike(id, userId)
      .then(async function (response) {
        if (response.status == 200) {
          console.log(response.data);
          // window.location.href = '/favorites';
          await fetchData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleAddCollectionsClick = async (id: any) => {
    setUnsetUserFavorite(userId, id)
      .then(async function (response) {
        if (response.status === 200) {
          console.log(response.data);
          // window.location.href = '/favorites';
          await fetchData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const CardItem = ({ id, resourceName, resourceDescription, content, resourceLink, like, favorite, likesCount, image, wikiLink }: CardData) => (


    <Card
      style={{ background: 'lightgrey' }} sx={{ maxWidth: 300 }}>
      <Tooltip title={content} placement="bottom">

        <CardHeader
          avatar={
            <IconButton onClick={() => handleLinkClick(resourceLink)}>
              <Avatar sx={{
                width: 25, height: 25,
                fontSize: '0.5rem', bgcolor: red[500], marginLeft: -1.5
              }} aria-label="report">
                {resourceName}
              </Avatar>
            </IconButton>
          }
          title={<IconButton sx={{ paddingLeft: 0, margin: 0 }} onClick={() => handleLinkClick(resourceLink)}> <Typography sx={{ fontSize: '0.75rem', color: 'blue', fontWeight: 1000 }}><b>{resourceDescription}</b></Typography></IconButton>}
          // subheader={<Typography variant="h6" sx={{ fontSize: '0.5rem', margin: 0 }}>{currentDate.toDateString()}</Typography>}
          sx={{
            '& .MuiCardHeader-title': {
              marginBottom: '-8px', // Adjust this value as needed to reduce space
            },
            '& .MuiCardHeader-subheader': {
              marginTop: '-8px', // Adjust this value as needed to reduce space
            },
            marginBottom: '-20px',
            marginTop: '-10px'
          }}
        />
      </Tooltip>
      <Tooltip title={content} placement="bottom">
        <IconButton onClick={() => handleLinkClick(resourceLink)} style={{ cursor: 'pointer' }}>

          <CardMedia
            className={classes.media}
            component={"img"}
            image={require('../../../assets/images/' + image + '.png')}
            width='100%'
            height='auto'
            sx={{ padding: "0 0.10em", objectFit: "contain", objectPosition: 'center center' }}
          />
        </IconButton>
      </Tooltip>

      <CardActions sx={{ backgroundColor: 'grey', height: 30, paddingLeft: 12 }}>
        <div className='ml-2'>
          <Tooltip title={"Like"} placement="bottom">
            <IconButton
              onClick={() => handleLikeClick(id)}>
              <ThumbUpIcon style={{ color: like ? 'blue' : 'white', height: 12, width: 12 }} />             <div className='likesCount'>{(likesCount != 0) ? likesCount : ''}</div>

            </IconButton>
          </Tooltip>

          <Tooltip title={"Add to My Collections"} placement="bottom">
            <IconButton aria-label="favourite" onClick={() => handleAddCollectionsClick(id)}>
              <FavoriteIcon style={{ color: favorite ? 'red' : 'white', height: 15, width: 15 }} />
              {/* <img src={(favorite) ? addedCollections : addCollections} style={{height: 15, width: '100%'}} /> */}
            </IconButton>
          </Tooltip>

          <Tooltip title={"Click Here to know more"} placement="bottom">

            <IconButton>
              {(wikiLink != null) ?
                <a href='#' onClick={() => handleLinkClick(wikiLink)} rel="noopener noreferrer">
                  <img className="image-card" alt="wiki" src={wikiImage} />
                </a> :
                <img className="image-card" alt="wiki" src={wikiImage} />}
            </IconButton>
          </Tooltip>
        </div>
      </CardActions>
    </Card>
  );

  // return (
  //   <div>
  //     <h1 className='page-header mb-3'>Safety & Performance Insights</h1>

  //     <div className="containerCard">
  //       <img className="backgroundImage" src={background} alt="Background" />
  //       <div>
  //         {isLoading && (
  //           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
  //             <p><CircularProgress /></p>
  //           </div>
  //         )}
  //         <Grid container spacing={2}>
  //           {cardData.map((card, index) => (
  //             <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
  //               <CardItem {...card} />
  //             </Grid>
  //           ))}
  //         </Grid>
  //       </div>
  //     </div>
  //   </div>
  // );
  return (
    <div>
      <h1 className='page-header mb-3'>Safety & Performance Insights</h1>
        <div className='containerCard'>
          <img className='backgroundImage' src={background} alt="Background" />
            <div className="dataGridContainer">

            <DataGrid
             sx={{
              "& .MuiDataGrid-columnHeader.header": {
                backgroundColor: "lightgray",
                color: "#000",
                fontSize: '14px',
                
              },
              "& .MuiDataGrid-row.Mui-odd": {
                backgroundColor: "aliceblue",
              },
              "& .MuiDataGrid-row.Mui-even": {
                backgroundColor: "#fcfdff",
              }
            }}
              rows={cardData}
              rowHeight={40}
              columns={columns}
              loading={isLoading}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
              }
              columnHeaderHeight={30}
              />          
            </div>
          </div>
        </div>
  );
};

export default SafetyPerformanceInsightComponent;