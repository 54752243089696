import './_sidebar.css';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
  SidebarFooter,
  SubMenu,
} from "react-pro-sidebar";

import { YammerIcon } from '../../reusableComponents/svgIcons';
import { useLocation, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MemoryIcon from '@mui/icons-material/Memory';
import AppsIcon from '@mui/icons-material/Apps';
import DataIcon from '@mui/icons-material/Storage';
import ManagementIcon from '@mui/icons-material/FactCheck';

import { openLink } from '../../reusableComponents/Utilities';
// import { useUserContext } from '../../pages/authentication/userContext';
// import { getPermissions } from '../../../services/user.service';
// import { all } from 'axios';
import React from 'react';


interface SidebarProps {
  isOpen: boolean;
  activeSubMenu: string;
  allowedResources: string[];
  isAdmin: boolean;
  isDefaultUser: boolean;
}

interface SidebarConfig {
  name: string;
  path: string;
}

const ProSidebarMenu: React.FC<SidebarProps> = ({ isOpen, activeSubMenu, allowedResources, isAdmin, isDefaultUser }) => {
  const [features, setFeatures] = useState<SidebarConfig[]>([]);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredFooter, setIsHoveredFooter] = useState(false);
  console.log("sidebar refreshed " + allowedResources);
  const [activeMenu, setActiveMenu] = useState<string | null>(() => {
    const storedActiveMenu = localStorage.getItem('activeMenu');
    return storedActiveMenu !== null ? storedActiveMenu : null;
  });

  const handleMenuClick = (menuPath: string) => {
    setActiveMenu(prevActiveMenu => prevActiveMenu === menuPath ? null : menuPath);
  };

  useEffect(() => {
    localStorage.setItem('activeMenu', activeMenu !== null ? activeMenu : '')
    
  }, [activeMenu]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseEnterFooter = () => {
    setIsHoveredFooter(true);
  };

  const handleMouseLeaveFooter = () => {
    setIsHoveredFooter(false);
  };

  const handleLinkClick = (url: string) => {
    window.open(url, '_blank', openLink());
  };


  const location = useLocation();
  const SidebarData = [
    {
      name: "Home",
      path: "/",
      image: <HomeIcon/>
    },
    {
      name: "My Collections",
      path: "/collections",
      image: <FavoriteIcon/>
    },
    {
      name: "Analytics & Trending Dashboard",
      subItems: [
        {
          name: "Global Complaint Processing",
          path: "/analytics&TrendingDashboard/complaintProcessingDashboard"
        },
        {
          name: "Safety & Performance Insights",
          path: "/analytics&TrendingDashboard/performanceInsights"
        },
        {
          name: "Quality Functions",
          path: "/analytics&TrendingDashboard/capaDashboards"
        }
      ],
      path: "/analytics&TrendingDashboard",
      image: <DashboardIcon/>
    },
    {
      name: "Complaint Code Management",
      subItems: [
        {
          name: "Complaint Codes Discovery (BETA)",
          path: "/complaintCodeMgmt/discovery"
        },
      {
          name: "Request Code Change (BETA)",
          path: "/complaintCodeMgmt/requestChange"
        }
      ],
      path: "/complaintCodeMgmt",
      image: <ManagementIcon />
      
    },    
    // {
    //   name: "Data Discovery",
    //   subItems: [
    //     {
    //       name: "Glossary",
    //       path: "/dataDiscovery/glossary"
    //     },
    //   {
    //       name: "Data Consumption",
    //       path: "/dataDiscovery/dataConsumption"
    //     }
    //   ],
    //   path: "/dataDiscovery",
    //   image: <DataIcon/>
    // },    
    {
      name: "Apps & Tools",
      path: "/apps&Tools",
      image: <AppsIcon/>
    },
    {
      name: "Interactive Analysis Zone",
      path: "/interactiveAnalyticsZone",
      external: true,
      image: <MemoryIcon/>
    },
  ]

  const SidebarFooterData = 
    {
      name:"Help & Support",
      path: "/help"
    }
  

  return (
    <div className="sidebar">
      <ProSidebar>
        <SidebarContent>

          {SidebarData.map((menuItem, index) => (
            // Check if isAdmin or if the resource is allowed
            (isAdmin  || allowedResources.includes(menuItem.name)) ? (
              <Menu >
                <React.Fragment key={index}>
                  {menuItem.subItems ? (
                    <SubMenu
                      title={menuItem.name}
                      icon={menuItem.image}
                      open={activeMenu === menuItem.path}
                      onOpenChange={() => handleMenuClick(menuItem.path)}
                    >

                      {menuItem.subItems.map((subItem, subIndex) => (
                        <MenuItem key={subIndex}
                          onClick={() => console.log(subItem.path)}
                          active={location.pathname === subItem.path}
                          className={isHovered ? 'active-item' : '' }
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}>
                          {subItem.name}
                          <Link to={subItem.path} />
                        </MenuItem>
                      ))}

                    </SubMenu>
                  ) : (
                    <MenuItem key={index} 
                      onClick={() => handleMenuClick(menuItem.path)} 
                      active={location.pathname === menuItem.path}
                      className={isHovered ? 'active-item' : ''}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      icon = {menuItem.image}>
                      {menuItem.name}
                      <Link to={menuItem.path} />
                    </MenuItem>
                  )}
                </React.Fragment>
              </Menu>
            ) : null
          ))}

        </SidebarContent>
        <SidebarFooter style={{ overflow: 'hidden' }}>
        <div className="sidebarFooterFlex justify-content-center">
                <Menu>
                    <p>{SidebarFooterData.name}</p>
                    <Link to={SidebarFooterData.path}/>
                </Menu>
        </div>            
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
}

export default ProSidebarMenu;
