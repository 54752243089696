import React, { useEffect, ReactNode } from 'react';
import { useMsal } from '@azure/msal-react';

interface AuthProps {
  children: ReactNode;
}

const AuthComponent: React.FC<AuthProps> = ({ children }) => {
  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {

    const handleRedirect = async () => {
      if (accounts.length === 0 && inProgress === "none" ) {
        await instance.loginRedirect();
      }
    };

    handleRedirect();

  }, [instance, inProgress, accounts]);


        
  return <>{children}</>;
};

export default AuthComponent;
