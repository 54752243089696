import React from 'react';
import background from '../../../assets/images/PhilipsAzurion.jpg';
import click from '../../../assets/images/excel.jpg';
import { Tooltip } from '@mui/material';
import Marquee from "react-fast-marquee";

const CapaComponent: React.FC = () => {

    return (
        <div>
            <Marquee className='flow-text mb-4'>
                * For access, please submit a request to dl_PMSAnalytics_DE@philips.com
            </Marquee>
            
            <div className='backgroudContainer'>


                <img alt="background" src={background} className="backgroundImage" />

                <Tooltip title='Click Here To View' placement="right">

                    <a href="ms-excel:ofe|u|https://share.philips.com/:x:/r/sites/PMS464/Shared%20Documents/General/Data%20Engineering/00%20Command%20Center%20Files/CAPA%20Tracker%20Owned%20by%20GCM.xlsx">
                        <img alt="excel" src={click} className="excelIcon" />
                    </a>

                </Tooltip>

            </div>
 
            <div className="textContainer">
                CAPA Tracker Owned by GCM
            </div>
        </div>
    );
};

export default CapaComponent;
