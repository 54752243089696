import React, { useState } from "react";

// import of react-router-dom to manage navigations
import { Navigate, Route, Routes } from 'react-router-dom';
// import of style
import './sass/style.css';

// import of reuseable components
import Layout from './components/layout/layout';
import ComplaintProcessingDashboardComponent from './components/pages/analyticsTrendingDashboard/complaintProcessindDashboardIndex';
import AI from './components/pages/aI/aIIndex';

import DataService from './components/pages/dataDiscovery/dataLiberationIndex';
import Home from './components/pages/home/home';
import ComplainCodesDiscovery from './components/pages/ccm/ccmDiscovery.component';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { PublicClientApplication } from '@azure/msal-browser';
import SafetyPerformanceInsightComponent from './components/pages/analyticsTrendingDashboard/safetyPerformanceInsightsIndex';
import ChangeRequesst from './components/pages/ccm/ccmRequest';
import { UserProvider } from "./components/pages/authentication/userContext";
import Favorite from "./components/pages/favorites/myFavorites";
import AuthComponent from "./AuthComponent";
import QualityFunctionalDashboardComponent from "./components/pages/analyticsTrendingDashboard/qualityFunctionalDashboardIndex";
import FooterPage from "./components/layout/footer/footerPage";
import AppsAndTools from "./components/pages/apps/appsAndTools";
import Glossary from "./components/pages/dataDiscovery/glossary";
import DataConception from "./components/pages/dataDiscovery/dataConception";


const App : React.FC<{ msalInstance: PublicClientApplication }> = ({ msalInstance }) => {
  const [activeSubMenu, setActiveSubMenu] = useState<string>('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);


  // const isAuthenticated: () => boolean = () =>  {
  //   const { accounts } = useMsal();
  //   return accounts && accounts.length > 0;
  // }
  return (
    <MsalProvider instance={msalInstance}>
           <AuthComponent>
        <UserProvider>
           <Layout>
          <Routes>
            <Route path='/' element={<Home openSidebar={() => setIsSidebarOpen(true)}
              setActiveSubMenu={setActiveSubMenu} />} />
            <Route path="/collections" element={<Favorite />} />
            <Route path='/analytics&TrendingDashboard/complaintProcessingDashboard' element={ <ComplaintProcessingDashboardComponent />  } />
            <Route path='/analytics&TrendingDashboard/performanceInsights' element={<SafetyPerformanceInsightComponent /> } />
            <Route path='/analytics&TrendingDashboard/capaDashboards' element={ <QualityFunctionalDashboardComponent />  } />
            <Route path='/complaintCodeMgmt/discovery' element={<ComplainCodesDiscovery />  } />
            <Route path='/complaintCodeMgmt/requestChange' element={ <ChangeRequesst /> }  />
            <Route path='/apps&Tools' element={ <AppsAndTools /> }  />
            <Route path='/dataDiscovery/glossary' element={<Glossary />  } />
            <Route path='/dataDiscovery/dataConsumption' element={<DataConception />  } />

            {/* <Route path='/patientSafetyAndQualityReports' element={<QualityReports />} />
        <Route path='/regulatoryReports' element={<RegulatoryReports />} />
        <Route path='/vigilanceReports' element={<VigilanceReports />} /> */}
            <Route path='/interactiveAnalyticsZone' element={<AI />} />
            <Route path='/help' element={<FooterPage />} />
      
          </Routes>
          </Layout> 
        </UserProvider>
      </AuthComponent>
    </MsalProvider>
  );
}

export default App;

