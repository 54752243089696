import background from '../../../assets/images/PhilipsAzurion.jpg';

import { openLink } from "../../reusableComponents/Utilities";
import { Avatar, Card, CardActions, CardHeader, CardMedia, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';


export const handleLinkClick = (url: string) => {
  console.log("Link clicked:", url);
  window.open(url, '_blank', openLink());
};

const AppsAndTools = () => {
  

    


  return (
    <div>

      <h1 className='page-header mb-3'>Apps & Tools</h1>

      {/* <div className='containerCard'>

        <img alt="background" src={team} className="backgroundImage" />

        <Grid container spacing={0}>

          <Grid item xs={3} >
            <div className='iconHeader'>Corrections & Removals</div>

            <a href="#" onClick={() => handleLinkClick("https://apps.powerapps.com/play/e/default-1a407a2d-7675-4d17-8692-b3ac285306e4/a/2f93edf7-130a-4040-aeb9-15e4984a38de?tenantId=1a407a2d-7675-4d17-8692-b3ac285306e4&hint=05145b7a-cc33-40d2-8ec7-e602c8413bff&sourcetime=1714646878439")} rel="noopener noreferrer" >
              <img alt="cnr" src={cnr} className="excelIcon" height={100}/>
            </a>

          </Grid>

          <Grid item xs={3} >
           <div className='iconHeader'>Submit Request</div>

            <a href="#" onClick={() => handleLinkClick("https://apps.powerapps.com/play/e/default-1a407a2d-7675-4d17-8692-b3ac285306e4/a/aaf53a5d-2fca-4f10-afc1-ca80cf85bb0d?tenantId=1a407a2d-7675-4d17-8692-b3ac285306e4&sourcetime=1714631278512&source=portal")} rel="noopener noreferrer" >
              <img alt="submitRequest" src={submitRequest1} className="excelIcon" height={100} />
            </a>

          </Grid>

        </Grid>
        

      </div> */}
      <div className="containerCard">
        <img className="backgroundImage" src={background} alt="Background" />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4} >
            <Card
              style={{ background: 'lightgrey' }} sx={{ maxWidth: 300 }}>
              <Tooltip title={"Application for C&R reconciliation for international market regions."} placement="bottom">

                <CardHeader

                  title={<IconButton onClick={() => handleLinkClick("https://apps.powerapps.com/play/e/default-1a407a2d-7675-4d17-8692-b3ac285306e4/a/2f93edf7-130a-4040-aeb9-15e4984a38de?tenantId=1a407a2d-7675-4d17-8692-b3ac285306e4&hint=05145b7a-cc33-40d2-8ec7-e602c8413bff&sourcetime=1714646878439")}> <Typography sx={{ fontSize: '0.5em', color: 'blue', fontWeight: 1000 }}><b>{"Corrections & Removals Due Diligence"}</b></Typography></IconButton>}
                  sx={{
                    '& .MuiCardHeader-title': {
                      marginBottom: '-8px',
                      textAlign: 'center'
                    },
                    marginBottom: '-20px',
                    marginTop: '-10px'
                  }}
                />
              </Tooltip>
              <Tooltip title={"Application for C&R reconciliation for international market regions."} placement="bottom">
                <IconButton onClick={() => handleLinkClick("https://apps.powerapps.com/play/e/default-1a407a2d-7675-4d17-8692-b3ac285306e4/a/2f93edf7-130a-4040-aeb9-15e4984a38de?tenantId=1a407a2d-7675-4d17-8692-b3ac285306e4&hint=05145b7a-cc33-40d2-8ec7-e602c8413bff&sourcetime=1714646878439")} style={{ cursor: 'pointer' }}>

                  <CardMedia
                    component={"img"}
                    image={require('../../../assets/images/cnrDueDiligence.png')}
                    width='100%'
                    height='auto'
                    sx={{ padding: "0 0.10em", objectFit: "contain", objectPosition: 'center center' }}
                  />
                </IconButton>
              </Tooltip>

              <CardActions sx={{ backgroundColor: 'grey', height: 30, width: '100%', justifyContent: 'flex-end' }}>
                <div className='ml-2'>

                  <IconButton disabled aria-label="favourite" >
                    <FavoriteIcon style={{ color: 'white', height: 15, width: 15 }} />
                  </IconButton>


                </div>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} >
            <Card
              style={{ background: 'lightgrey' }} sx={{ maxWidth: 300 }}>
              {/* <Tooltip title={"Application for submitting analytics request to PMS COE shared services."} placement="bottom" PopperProps={{
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 170],
        },
      },
    ],
  }}> */}
  <Tooltip title={"Application for submitting analytics request to PMS COE shared services."} placement="bottom" 
    >

                <CardHeader

                  title={<IconButton onClick={() => handleLinkClick("https://apps.powerapps.com/play/e/default-1a407a2d-7675-4d17-8692-b3ac285306e4/a/aaf53a5d-2fca-4f10-afc1-ca80cf85bb0d?tenantId=1a407a2d-7675-4d17-8692-b3ac285306e4&sourcetime=1714631278512&source=portal")}> <Typography sx={{ fontSize: '0.5em', color: 'blue', fontWeight: 1000 }}><b>{"Request Submission"}</b></Typography></IconButton>}
                  sx={{
                    '& .MuiCardHeader-title': {
                      marginBottom: '-8px',
                      textAlign: 'center'
                    },
                    marginBottom: '-20px',
                    marginTop: '-10px'
                  }}
                />
              </Tooltip>
              <Tooltip title={"Application for submitting analytics request to PMS COE shared services."} placement="bottom">
                <IconButton onClick={() => handleLinkClick("https://apps.powerapps.com/play/e/default-1a407a2d-7675-4d17-8692-b3ac285306e4/a/aaf53a5d-2fca-4f10-afc1-ca80cf85bb0d?tenantId=1a407a2d-7675-4d17-8692-b3ac285306e4&sourcetime=1714631278512&source=portal")} style={{ cursor: 'pointer' }}>

                  <CardMedia
                    component={"img"}
                    image={require('../../../assets/images/submitRequest.png')}
                    width='100%'
                    height='auto'
                    sx={{ padding: "0 0.10em", objectFit: "contain", objectPosition: 'center center' }}
                  />
                </IconButton>
              </Tooltip>

              <CardActions sx={{ backgroundColor: 'grey', height: 30, width: '100%', justifyContent: 'flex-end' }}>
                <div className='ml-2'>

                  <IconButton disabled aria-label="favourite" >
                    <FavoriteIcon style={{ color: 'white', height: 15, width: 15 }} />
                  </IconButton>


                </div>
              </CardActions>
            </Card>
          </Grid>

        </Grid>

      </div>
    </div>
      
  );
}
export default AppsAndTools;