import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { purple } from '@mui/material/colors';
import background from '../../../assets/images/PhilipsAzurion.jpg';
import { Avatar,    Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useUserContext } from '../authentication/userContext';
import { getResources, setUnsetUserFavorite, setUnsetUserLike } from '../../../services/user.service';
import { CardData } from './CardData';
import { openLink } from '../../reusableComponents/Utilities';
import CustomActionsColumn from './customActionsColumn';


const useStyles = makeStyles({
  root: {
    '& .alternate-row': {
      backgroundColor: 'lightgray', 
    },
   
  }
});



const Tier2ListComponent = () => {
  const { userId } = useUserContext();
  const [cardData, setCardData] = useState<CardData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleLinkClick = (url: string) => {
    window.open(url, '_blank', openLink());
  };

  const columns: GridColDef[] = [

    {
      field: 'resourceId', headerName: 'Id', flex: 0.3, headerClassName: 'header', renderCell : (params) => {
        return (
          <div style={{fontSize: '10px'}}> {params.row.resourceId} </div>
        );
      }
    },
    {
      field: 'resourceDescription', headerName: 'Report Name', flex: 1, headerClassName: 'header', headerAlign: 'center', sortable: false,
      renderCell: (params) =>
        <div > <Avatar sx={{
          width: 20, height: 20,
          fontSize: '0.4rem', bgcolor: purple[800], marginTop: '3px'
        }} aria-label="report">{params.row.resourceName}</Avatar>
          <div style={{ fontSize: '12px', marginLeft: '30px', marginTop: '-33px' }}>
            <a href='#' onClick={() => handleLinkClick(params.row.resourceLink)}>
              <Tooltip title='Click Here To View Reports'>
                {params.row.resourceDescription}
              </Tooltip>
            </a>
          </div>
        </div>
    },
    {
      field: 'content', headerName: 'Report Description', flex: 2.4, minWidth: 200, headerClassName: 'header', headerAlign: 'center', sortable: false, 
      renderCell: (params) => {
        return (
          // <span style={{ color: 'red' }}>{params.colDef.headerName}</span>
          <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflow: 'visible', lineHeight: '1.5', fontSize: '11px' }}>{params.value}</div>
        );
      }
    },
    { field: 'actions', headerName: 'Actions', flex: 0.6, headerClassName: 'header', headerAlign: 'center',   sortable: false, 
    disableColumnMenu: true,
    renderCell: (params: any) => (
      
      <CustomActionsColumn
        row={params.row}
        handleLikeClick={handleLikeClick}
        handleAddCollectionsClick={handleAddCollectionsClick}
        handleLinkClick={handleLinkClick}
      />
    )
    }


  ];

  cardData.forEach((row, index) => {
    row.resourceId = index + 1;
  });
  
  const fetchData = async () => {
    getResources("Complaint Processing Dashboards", userId, 1, true, "tier2")
      .then(function (response) {
        if (response.status === 200) {
          setCardData(response.data);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  }

  useEffect(() => {

    if (userId === null || userId === 0) {

      window.location.href = '/';
    } else {
      setIsLoading(true);
      fetchData();
    }
  }, [])


  const handleLikeClick = (id: number) => {

    setUnsetUserLike(id, userId)
      .then(async function (response) {
        if (response.status === 200) {
          console.log(response.data);
          await fetchData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleAddCollectionsClick = async (id: any) => {
    setUnsetUserFavorite(userId, id)
      .then(async function (response) {
        if (response.status === 200) {
          console.log(response.data);
          await fetchData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  return (
    <div>
     
        <div className='containerCard'>
          <img className='backgroundImage' src={background} alt="Background" />
            <div className="dataGridContainer">

            <DataGrid
             sx={{
              "& .MuiDataGrid-columnHeader.header": {
                backgroundColor: "lightgray",
                color: "#000",
                fontSize: '14px',
                
              },
              "& .MuiDataGrid-row.Mui-odd": {
                backgroundColor: "aliceblue",
              },
              "& .MuiDataGrid-row.Mui-even": {
                backgroundColor: "#fcfdff",
              }
            }}
              rows={cardData}
              rowHeight={40}
              columns={columns}
              loading={isLoading}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
              }
              columnHeaderHeight={30}
              />          
            </div>
          </div>
        </div>


  );
};

export default Tier2ListComponent;