import email from '../../../assets/images/email.png';
import { SendEmail } from '../../pages/email/sendEmail';
import FAQ from './faq';

const FooterPage = () => {
    return (
        <section>
            <div className='container'>
                <div className='row justify-content-start'>
                    <div>
                        <h5>Contact Information</h5>
                        <hr />
                    </div>
                </div>
                <div className='row justify-content-start'>
                    <div className='col-auto mt-2'>
                        <img src={email} height='40px' width='40px' />
                    </div>
                    <div className='col'>
                        <h5 className='tab-header p-2 mb-3' style={{ textAlign: 'left' }}>Email us</h5>
                        <button type="button" className='btn custom-button' onClick={() => SendEmail()}>Email us</button>
                    </div>
                </div>

                <hr className='mt-4' />
            </div>
            <h5>Frequently Asked Questions</h5>

            {/* <FAQ/> */}
        </section>
    )
}

export default FooterPage;
