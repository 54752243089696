import {  Tabs } from 'antd';
import type { TabsProps } from 'antd';
import Tier2ListComponent from './tier2List';
import Tier3ListComponent from './tier3List';
import Tier1ListComponent from './tier1List';


const ComplaintProcessingDashboardComponent = () => {
    const onChange = (key: string) => {
      console.log(key);
    };
  
    const items: TabsProps['items'] = [
      {
        key: '1',
        label: `Operational Dashboards (Tier1)`,
        children: <Tier1ListComponent/>,
      },
      {
        key: '2',
        label: `Manager Dashboards (Tier2)`,
        children: <Tier2ListComponent/>,
      },
      {
        key: '3',
        label: `Leadership Dashboards (Tier3)`,
        children: <Tier3ListComponent/>
      },
    ];
    return (
      <section className='projectActions'>
              <h1 className='page-header mb-3'>Global Complaint Processing</h1>
              
              <Tabs defaultActiveKey="1" type="card" items={items} onChange={onChange} />
      </section>
    )
  
}
export default ComplaintProcessingDashboardComponent;

