
const DataConception = () => {
  
    return (
      <section>
        <div>
          <h1 className='page-header mb-3'> Welcome To Data  Consumption</h1>
        </div>
        
        
           
      </section>
    )
  }
  
  
  export default DataConception
  
  
  
  
  
  