import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';

const pca = new PublicClientApplication ({
  auth: {
    clientId: "bc7321c0-1fdf-4f78-be0d-8a5241531634",
    authority: "https://login.microsoftonline.com/1a407a2d-7675-4d17-8692-b3ac285306e4",
    redirectUri: "https://www.cc.pms.philips.com/" // Your app's redirect URI
  }, 
  cache: {
    cacheLocation: 'localStorage',
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <App msalInstance={pca} />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
