import http from "../http-common";

const ccmService = "/ccmapi"

export const getProductsByCatalog = (catalogItemId: string) => {
    return http.get(ccmService + "/productFamilies?catalogItemId=" + catalogItemId);
}

export const getProblemsByproductFamily = (productFamily: string) => {
    return http.get(ccmService + "/reportedProblems?productFamily=" + productFamily);
}

export const getProblemSourceReasonsByproductFamily = (productFamily: string) => {
    return http.get(ccmService + "/problemSourceReasons?productFamily=" + productFamily);
}

export const getInvestigationDetailsByProblemSourceReasonProductFamily = (problemSource: string, problemReason: string, productFamily: string) => {
    return http.get(ccmService + "/investigationDetails?problemSource=" + encodeURIComponent(problemSource) + "&problemReason=" + encodeURIComponent(problemReason) + "&productFamily=" + encodeURIComponent(productFamily));
}

export const getDeviceUsageRiskHazardDetails = (rpc1: string, rpc2: string, rpc3: string, productFamily: string) => {
    return http.get(ccmService + "/deviceUseRiskHazardDetails?rpc1=" + encodeURIComponent(rpc1) + "&rpc2=" + encodeURIComponent(rpc2) + "&rpc3=" + encodeURIComponent(rpc3) + "&productFamily=" + encodeURIComponent(productFamily));
}
