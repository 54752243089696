import React, { useRef, useEffect } from 'react';

interface UserDropdownProps {
  userName?: string;
  userEmail?: string;
  profileName?: string;
  onSignOut: () => void;
  closeDropdown: () => void;
}

function UserDropdown({userName, userEmail, profileName, onSignOut, closeDropdown }: UserDropdownProps) {
  const dropdownRef = useRef<HTMLDivElement | null>(null); 

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeDropdown]);

  const handleSignOutClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onSignOut();
  };

  return (
    <div className="user-dropdown" ref={dropdownRef}>
    {/* <div className="user-dropdown" > */}
      <div className='signout float-start'>
        Philips
      </div>
      <button className='signout float-end' onClick={handleSignOutClick}>Sign out</button><br/>
      <div className='mega-menu d-flex'>
        <div className='profileDisplay large'>
            {profileName}          
        </div>
        <ul className='list-unstyled'>
      <li className='user-name'>
        <span className='ellipsis' data-fullname={userName}>
      {userName}
      </span>
        </li>
      <li className='user-email'> 
        {userEmail}
      </li>
      <li>
        <a href="https://myaccount.microsoft.com/?ref=MeControl" target="_blank">View account</a>
      </li>
      <li>
        <a href="https://eur.delve.office.com/" target="_blank">My Microsoft 365 profile</a>
      </li>
      
      </ul>
        </div>
    </div>

  );
}

export default UserDropdown;
