import { SearchIcon, VoiceSearchIcon, CompanyLogoIcon } from '../reusableComponents/svgIcons';
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import {  useMsal } from '@azure/msal-react';

import { useEffect, useState } from 'react';
import UserDropdown from '../pages/authentication/userDropDown';
import { AuthError } from 'msal';
import { addUser, getPermissions } from '../../services/user.service';
import { profile } from 'console';
import { useUserContext } from '../pages/authentication/userContext';

// type MsalError = {
//   errorCode: string;
//   errorMessage: string;
// };

interface HeaderProps {
  refreshLayout: () => void; 
  onSignInSuccess: (id: number) => void; 
}

const Header : React.FC<HeaderProps> = ({ refreshLayout, onSignInSuccess }) => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = accounts && accounts.length > 0;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
  
  const { userId, setUserId } = useUserContext();
  const [userName, setUserName] = useState(""); 
  const [userEmail, setUserEmail] = useState(""); 
  const [profileName, setProfileName] = useState(""); 

  const [userDetails, setUserDetails] = useState<any>(null);

  useEffect(() => {
    if (accounts.length > 0) {
      const activeAccount = accounts[0]; 
      const name = activeAccount.name || '';
      updateUserDetails(activeAccount.name, activeAccount.username);
      registerUser(name, activeAccount.username);
      console.log(userId);
      }
  }, [accounts]);

  const updateUserDetails = (name: string | undefined, email: string | undefined) => {
    if (name && email) {
    setUserName(name);
    createProfileName(name);
    setUserEmail(email);
    }
  };

  const handleUsernameClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
    console.log('Dropdown state:', isDropdownOpen); // Add this for debugging

  };

  // const handleLogin = () => {
    // try {
    // instance.loginPopup({
    //   scopes: ['openid', 'profile', 'user.read'],
    // }).then((response: any) => {
    //   if (response && response.account) {
    //     const activeAccount = response.account;
    //     updateUserDetails(activeAccount.name, activeAccount.username);
    //     registerUser(activeAccount.name,activeAccount.username);
    //   } else {
    //     console.error("Login failed");
    //   }})
    // } catch (error) {
    //   if ((error as AuthError).message && (error as AuthError).message.includes('User cancelled the flow')) {
    //     // Handle the user canceling the login action
    //     console.log('User canceled login.');
    //   } else {
    //     // Handle other errors
    //     console.error('Login error:', error);
    //   }
    // }
  // }
  const createProfileName = (fullName: string) => {
   let names = fullName.includes(",") ? fullName.split(",") : fullName;
   const firstName = getFirstLetter(names[0].trim());
   console.log(firstName);
   const lastName = getFirstLetter(names[1].trim());
   console.log(lastName);
   setProfileName(firstName+lastName);
  }

  const getFirstLetter = (name: string) => {
    return (name) && (name.length === 1) ? name : name.charAt(0);
  }
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  
  console.log('Rendering Header with isDropdownOpen:', isDropdownOpen); // Add this for debugging

  const registerUser = (name: string, email: string) => {
    let names = name.includes(",") ? name.split(",") : name;
    let firstName = names ? names[1] : name;
    let lastName = names ? names[0] : name;
    addUser(firstName,lastName,email)
    .then(function (response) {
      if (response.status === 200) {
        setUserId(response.data);
        console.log("successfully added");
        onSignInSuccess(response.data);
        refreshLayout();
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  return (
    <div className="main-header">
      <div className='placeHolderPlaceOfHeader'></div>
      <nav className="navbar navbar-default">
        <div className="container-fluid flex-nowrap align-items-center">
          <div className='header-menu-links hamburgerLink'>

            <div><CompanyLogoIcon /></div>
            {/* <div> Quality & Regulatory Command Center Portal</div> */}
          </div>
          <div className='subtitle-text'> Data Analytics Hub</div>
          <div className="pb-3">
            <div className='d-flex '>
              {/* <div>
                <div className="d-flex align-items-center">
                  <div >
                    <div className="headerSearch input-large">
                      <InputGroup>
                        <Form.Control
                          type='Search' disabled
                          placeholder="what are you looking for" 
                        />
                        <Button variant="outline-secondary" id="button-addon2">
                          <SearchIcon />
                        </Button>
                        <Button variant="outline-secondary" id="button-addon2">
                          <VoiceSearchIcon />
                        </Button>
                      </InputGroup>
                    </div>
                  </div>
              
                </div>
              </div> */}
              <div>
                <div className="d-flex flex-end ms-4 desktopTopButtons">
                  {/* <div className='p-text pl-2 text-uppercase'>
                    <ProfileIcon/>
                  </div> */}
                  {/* <div className='p-text pl-2 text-uppercase ms-4'>
                    <button type="button" className="btn btn-primary">Sign Up</button>
                  </div> */}
                  
              
                  <div className="user-container"  >
                  {userName ? (<div className="profileDisplay" onClick={handleUsernameClick} >
                      {profileName}
                    </div>) : <div className="NoprofileDisplay"  />}
 
                    {isAuthenticated && isDropdownOpen && (
                      <UserDropdown userName={userName} profileName={profileName} userEmail={userEmail} onSignOut={() => {instance.logout(); closeDropdown(); }} closeDropdown={closeDropdown} />
                    )}
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header