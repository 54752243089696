// want to know about more, read through below link
// https://react-bootstrap.netlify.app/components/carousel/#rb-docs-content

import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

interface PropsObj {
    name: string;
    imageSrc: string;
    powerBiLink: string;
    link: string;
    external:boolean;
}

export interface Props {
    carouselData: PropsObj[]
    openSidebar: () => void;
    setActiveSubMenu: (submenu: string) => void;
}

const ControlledCarousel = ({ carouselData, openSidebar, setActiveSubMenu }: Props) => {
    const isAuthenticated = useIsAuthenticated();

    const [index, setIndex] = useState(0);
    const navigate = useNavigate();

    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };

    const handleLinkClick = (url: string) => {
        window.open(url, '_blank', open());
    };
      
    const open = () => {
        const width = 1000;
        const height = 600;
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;
        const options = `width=${width},height=${height},left=${left},top=${top}`;
        return options;
    }
    
    const showAlert = () => {
        const alertDiv = document.createElement('div');
      alertDiv.style.position = 'fixed';
      alertDiv.style.top = '50%';
      alertDiv.style.left = '50%';
      alertDiv.style.transform = 'translate(-50%, -50%)';
      alertDiv.style.padding = '20px';
      alertDiv.style.backgroundColor = 'lightcoral';
      alertDiv.style.color = 'white';
      alertDiv.style.borderRadius = '5px';
      alertDiv.innerText = 'Please Sign In';

      document.body.appendChild(alertDiv);

      // Automatically remove the alert after a few seconds
      setTimeout(() => {
        document.body.removeChild(alertDiv);
      }, 2000);
    }
      

      const handleClick = (isExternal: boolean, url: string, name: string) => {
        // Navigate to feature A and set sidebar state to open or explored
        if (isExternal) {
            handleLinkClick(url);
        } else {
        navigate(url);
        setActiveSubMenu(name);
        }
      };

    return (
        <div className='imageSlider'>
            <Carousel  interval={5000} activeIndex={index} onSelect={handleSelect}>
                {
                    carouselData.map((data, index) => (
                        <Carousel.Item>
                            
                                    {/* <img
                                        className="d-block w-100"
                                        height={600}
                                        width={800}
                                        src={data.imageSrc}
                                        alt={`slide ${index}`}
                                        // onClick= {() => data.external ? handleLinkClick(data.link) : handleClick(data.external, data.link, data.name)}
                                        onClick= {() => isAuthenticated ?  handleClick(data.external, data.link, data.name) 
                                            : alert("Please Signin")}
                                    /> */}
             <img
  className="d-block w-100"
  height={600}
  width={800}
  src={data.imageSrc}
  alt={`slide ${index}`}
  onClick={() => {
    if (isAuthenticated) {
      handleClick(data.external, data.link, data.name);
    } else {
      showAlert();
    }
  }}
/>


                        </Carousel.Item>
                    ))
                }
            </Carousel>
             {/* <Image.PreviewGroup> 
    <Carousel autoplay infinite={false}>
        {carouselData.map(
            (data, index) => {
                return <Image key={index} src={data.download_url} preview={{ getContainer: '#root' }} />;
             }
         )}
    </Carousel>
</Image.PreviewGroup> */}
        </div>
    );
}

export default ControlledCarousel;