import { DataGrid, GridColDef } from '@mui/x-data-grid';
import background from '../../../assets/images/PhilipsAzurion.jpg';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useUserContext } from '../authentication/userContext';
import { getDataProducts } from '../../../services/data.service';
import { DataProduct } from './DataProduct';


const useStyles = makeStyles({
  root: {
    '& .alternate-row': {
      backgroundColor: 'lightgray', 
    },
   
  }
});



const Glossary = () => {
  const { userId } = useUserContext();
  const [dataProduct, setDataProduct] = useState<DataProduct[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  

  const columns: GridColDef[] = [

    {
      field: 'id', headerName: 'Id', flex: 0.3, headerClassName: 'header', renderCell : (params) => {
        return (
          <div style={{fontSize: '10px'}}> {params.row.id} </div>
        );
      }
    },
    {
      field: 'schemaName', headerName: 'Schema Name', flex: 1, headerClassName: 'header',  sortable: false,
      renderCell: (params) =>
        <div > 
                {params.row.schemaName}
             
        </div>
    },
    {
      field: 'tableName', headerName: 'Product Name', flex: 1, headerClassName: 'header', sortable: false,
      renderCell: (params) =>
        <div > 
                {params.row.tableName}
             
        </div>
    },
    {
      field: 'tableDescription', headerName: 'Product Description', flex: 2.4, minWidth: 200, headerClassName: 'header', sortable: false, 
      renderCell: (params) => {
        return (
          // <span style={{ color: 'red' }}>{params.colDef.headerName}</span>
          <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflow: 'visible', lineHeight: '1.5', fontSize: '11px' }}>{params.value}</div>
        );
      }
    },
    {
      field: 'ownerName', headerName: 'Owner Name', flex: 1, headerClassName: 'header', sortable: false,
      renderCell: (params) =>
        <div > 
                {params.row.ownerName}
             
        </div>
    },
    


  ];

  
  
  const fetchData = async () => {
    getDataProducts()
      .then(function (response) {
        if (response.status === 200) {
          setDataProduct(response.data);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  }

  useEffect(() => {

    if (userId === null || userId === 0) {

      window.location.href = '/';
    } else {
      setIsLoading(true);
      fetchData();
    }
  }, [])

  return (
    <div>
       <div>
          <h1 className='page-header mb-3'> Glossary </h1>
        </div>
        
        <div className='containerCard'>
          <img className='backgroundImage' src={background} alt="Background" />
            <div className="dataGridContainer">

            <DataGrid
             sx={{
              "& .MuiDataGrid-columnHeader.header": {
                backgroundColor: "lightgray",
                color: "#000",
                fontSize: '14px',
                
              },
              "& .MuiDataGrid-row.Mui-odd": {
                backgroundColor: "aliceblue",
              },
              "& .MuiDataGrid-row.Mui-even": {
                backgroundColor: "#fcfdff",
              }
            }}
              rows={dataProduct}
              rowHeight={40}
              columns={columns}
              loading={isLoading}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
              }
              columnHeaderHeight={30}
              />          
            </div>
          </div>
        </div>


  );
};

export default Glossary;