import pms from '../../../assets/images/pms.jpg';
import click from '../../../assets/images/clickhere.jpg'
import { useUserContext } from '../authentication/userContext';
import { useEffect } from 'react';

const handleLinkClick = (url: string) => {
  window.open(url, '_blank', open());
};

const open = () => {
  const width = 1000;
  const height = 600;
  const left = window.innerWidth / 2 - width / 2;
  const top = window.innerHeight / 2 - height / 2;
  const options = `width=${width},height=${height},left=${left},top=${top}`;
  return options;
}


const ChangeRequesst = () => {
 
  const { userId } = useUserContext();

  useEffect(() => {

    if (userId === null || userId === 0) {
      
      window.location.href = '/';
    } 
  }, [])


  return (
    <section>
      <h1 className='page-header'> Change Request</h1>
      <img className="centerImage" src={pms}/>
      
      <a href='#' onClick={() => handleLinkClick("https://apps.powerapps.com/play/e/default-1a407a2d-7675-4d17-8692-b3ac285306e4/a/41270edd-1a61-4d2c-9a99-5f154d6c4489?tenantId=1a407a2d-7675-4d17-8692-b3ac285306e4&hint=15ff1ee5-383b-467b-ac4b-dec8e8be8035&sourcetime=1701077752559")} rel="noopener noreferrer"><img src={click} className='sideImage'/></a>


    </section>
  )
}

export default ChangeRequesst
