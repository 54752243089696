import { IconButton, Tooltip } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import FavoriteIcon from '@mui/icons-material/Favorite';

import requestAccessImage from '../../../assets/images/requestaccess.png';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { RequestAccessIcon, WikiIcon } from '../../reusableComponents/svgIcons';
import { SendRequestAccessEmail } from '../email/sendEmail';


const CustomActionsColumn = ({ row, handleLikeClick, handleAddCollectionsClick, handleLinkClick }: any) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container rowSpacing={1} columnSpacing={0} sx={{ '& .MuiGrid-item': { height: '15px' } }}>
                <Grid item xs={3}>
                    <IconButton onClick={() => handleLikeClick(row.id)}>
                        <ThumbUpIcon style={{ color: row.like ? 'blue' : 'grey', marginBottom: 25, width: 15 }} />
                    </IconButton>
                </Grid>

                <Grid item xs={3} >
                    <IconButton onClick={() => handleAddCollectionsClick(row.id)} >
                        <FavoriteIcon style={{ color: row.favorite ? 'red' : 'grey', marginBottom: 25, width: 15 }} />
                    </IconButton>
                </Grid>
                       
                <Grid item xs={3} >
                    <IconButton>
                        <Tooltip title="Click Here to know more" placement="bottom">
                            <div style={{ marginTop: -5 }}>
                                {(row.wikiLink != null) ?
                                    <a href='#' onClick={() => handleLinkClick(row.wikiLink)} rel="noopener noreferrer" >
                                        {/* <img alt="wiki" src={wikiImage} height='auto' width='13px' style={{ marginBottom: 18}} /> */}
                                        <WikiIcon/>
                                    </a> :
                                    <WikiIcon/>
                                }
                            </div>
                        </Tooltip>
                    </IconButton>
                </Grid>
               
                <Grid item xs={3}>
                    <IconButton>
                    <div style={{ marginTop: -10 }}>
                        <Tooltip title="Click Here to get Access" placement="bottom">
                            <a href='#' onClick={() => SendRequestAccessEmail(row.resourceDescription, row.resourceLink)} rel="noopener noreferrer">
                                {/* <img alt="RequestAccess" src={requestAccessImage} height='auto' width='30px' style={{ marginBottom: 18 }} /> */}
                            <RequestAccessIcon />
                            </a>

                        </Tooltip>
                    </div>
                    </IconButton>
                </Grid>

                <Grid item xs={3} >
                    <div style={{ paddingLeft: '26px', fontSize: '9px'}}>
                        {row.likesCount !== 0 ? row.likesCount : ''}
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CustomActionsColumn;
