import { Avatar, Card, CardActions, CardHeader, CardMedia, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import background from '../../../assets/images/PhilipsAzurion.jpg';
import dmbImage from '../../../assets/images/dmb.png';
import gdtImage from '../../../assets/images/gdt.png';
import wipImage from '../../../assets/images/wip.png';
import kaiserImage from '../../../assets/images/kaizer.png';
import { red } from "@mui/material/colors";
import wikiImage from '../../../assets/images/wiki.png';
import pctrImage from '../../../assets/images/pctr.png';
import { makeStyles } from '@mui/styles';
import { getFavorites, setUnsetUserLike } from "../../../services/user.service";
import { useUserContext } from "../authentication/userContext";
import { CardData } from "../analyticsTrendingDashboard/CardData";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import FavoriteIcon from '@mui/icons-material/Favorite';
import addedCollections from '../../../assets/images/addedCollections.png';
import { openLink } from "../../reusableComponents/Utilities";


const useStyles = makeStyles({
  media: {
    cursor: 'pointer',
  },
});


export const handleLinkClick = (url: string) => {
  console.log("Link clicked:", url);
  window.open(url, '_blank', openLink());
};

const Favorite = () => {
  const { userId } = useUserContext();
  const [cardData, setCardData] = useState<CardData[]>([]);
  const [imageSrcs, setImageSrcs] = useState<Array<string | undefined>>([]);

  // const imageMapping: Record<string, string> = {
  //   'dmb': dmbImage,
  //   'gdt': gdtImage,
  //   'wip': wipImage,
  //   'kaizer': kaiserImage,
  //   'pctr': pctrImage
  // };


  useEffect(() => {
    getFavorites(userId)
      .then(function (response) {
        if (response.status === 200) {
          console.log(response.data);
          setCardData(response.data);

          // const srcs: Array<string | undefined> = response.data.map((card: CardData) => {
          //   const key = `${card.image}`;
          //   console.log(key);
          //   return imageMapping[key];
          // });
          // console.log('Image Sources:', srcs);

          // setImageSrcs(srcs);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  const handleLikeClick = (id: number) => {

    setUnsetUserLike(id, userId)
      .then(async function (response) {
        if (response.status === 200) {
          console.log(response.data);
          // window.location.href = '/favorites';

        }
      })
      .catch(function (error) {
        console.log(error);
      });


  };

  // const currentDate = new Date();

  const classes = useStyles();

  const CardItem = ({ id, resourceName, resourceDescription, content, resourceLink, like, likesCount, favorite, image, wikiLink }: CardData) => (


    <Card
      style={{ background: 'lightgrey' }} sx={{ maxWidth: 300 }}>
      <Tooltip title={content} placement="bottom">

        <CardHeader
          avatar={
            <IconButton onClick={() => handleLinkClick(resourceLink)}>
              <Avatar sx={{
                width: 25, height: 25,
                fontSize: '0.5rem', bgcolor: red[500], marginLeft: -1.5
              }} aria-label="report">
                {resourceName}
              </Avatar>
            </IconButton>
          }
          title={<IconButton sx={{ paddingLeft: 0, margin: 0 }} onClick={() => handleLinkClick(resourceLink)}> <Typography sx={{ fontSize: '0.75rem', color: 'blue', fontWeight: 1000 }}><b>{resourceDescription}</b></Typography></IconButton>}
          // subheader={<Typography variant="h6" sx={{ fontSize: '0.5rem', margin: 0 }}>{currentDate.toDateString()}</Typography>}
          sx={{
            '& .MuiCardHeader-title': {
              marginBottom: '-8px', // Adjust this value as needed to reduce space
            },
            '& .MuiCardHeader-subheader': {
              marginTop: '-8px', // Adjust this value as needed to reduce space
            },
            marginBottom: '-20px',
            marginTop: '-10px'
          }}
        />
      </Tooltip>
      <Tooltip title={content} placement="bottom">
        <IconButton onClick={() => handleLinkClick(resourceLink)} style={{ cursor: 'pointer' }}>

          <CardMedia
            className={classes.media}
            component={"img"}
            // src={imageSrcs.find((src) => src && src.includes(image))}
            image = {require('../../../assets/images/'+image+'.png')}

            width='100%'
            height='auto'
            sx={{ padding: "0 0.10em", objectFit: "contain", objectPosition: 'center center' }}
          />
        </IconButton>
      </Tooltip>

      <CardActions sx={{ backgroundColor: 'grey', height: 30, paddingLeft: 12 }}>
        <div className='ml-2'>
          <IconButton disabled>
            <ThumbUpIcon style={{ color: like ? 'blue' : 'white', height: 12, width: 12, fontSize: 7 }} />
            <div className='likesCount'>{(likesCount != 0) ? likesCount : ''}</div>

          </IconButton>
          <IconButton disabled aria-label="favourite" >
            <FavoriteIcon style={{ color: 'red', height: 15, width: 15 }} />
            {/* <img src={addedCollections} style={{  height: 15, width: '100%' }} /> */}
          </IconButton>

          <Tooltip title={"Click Here to know more"} placement="bottom">

            <IconButton>
              <a href='#' onClick={() => handleLinkClick(wikiLink)} rel="noopener noreferrer">
                <img className="image-card" alt="wiki" src={wikiImage} />
              </a>
            </IconButton>
          </Tooltip>
        </div>
      </CardActions>
    </Card>



  );

  return (
    <div>

      <h1 className='page-header mb-3'>My Collections</h1>

      <div className="containerCard">
        <img className="backgroundImage" src={background} alt="Background" />

        <Grid container spacing={2}>
          {cardData.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
              <CardItem {...card} />
            </Grid>
          ))}
        </Grid>

      </div>
    </div>
  );
}
export default Favorite;