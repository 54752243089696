import {  Tabs, Typography } from 'antd';
import type { TabsProps } from 'antd';
import CnrComponent from './cnrComponent';
import CapaComponent from './capaComponent';


const QualityFunctionalDashboardComponent = () => {
    const onChange = (key: string) => {
      console.log(key);
    };
  
    const items: TabsProps['items'] = [
      {
        key: '1',
        label: `Corrective Action And Preventive Action (CAPA)`,
        // children:       <h6 className='tab-header mb-5'>CAPA Dashboards</h6>
        children: <CapaComponent/>
      },
      {
        key: '2',
        label: `Corrections & Removals (C&R)`,
        children: <CnrComponent/>,
      }
    ];
    return (
      <section className='projectActions'>
              {/* <h4 className='header-subtitle-text mb-3'> Quality Functionals</h4> */}
              <h1 className='page-header mb-3'>Quality Functions</h1>

              <Tabs defaultActiveKey="1" type="card" items={items} onChange={onChange} />
              {/* <Tabs defaultActiveKey="1" type="card" tabBarStyle={{ width: '100%' }} onChange={onChange}>
      {items.map((item, index) => (
        <Tabs.TabPane key={index + 1} tab={item.label} style={{ flex: '1 1 0%' }}>
          {item.children}
        </Tabs.TabPane>
      ))}
    </Tabs> */}
      </section>
    )
  
}
export default QualityFunctionalDashboardComponent;

