import http from "../http-common";
import IUser from "../types/user.type";

// const userService = "/users/api/v1/user"
const userService = "/usersapi/user"
export const addUser = (firstName: string, lastName: string, email: string) => {
    const user: IUser = { "firstName": firstName, "lastName": lastName, "emailId": email };
    return http.post(userService, user);
}

export const setUnsetUserFavorite = (userId: number, resourceId: number) => {
    return http.post(userService + "/" + userId + "/resource/" + resourceId + "/favorites");
}

export const getResources = (featureName: string, userId: number, resourceTypeId: number, isActive: boolean, type: string) => {
    return http.get(userService + "/resources?featureName=" + encodeURIComponent(featureName) + "&userId=" + userId + "&resourceTypeId=" + resourceTypeId + "&isActive=" + isActive + "&type=" + type);
}

export const getFavorites = (userId: number) => {
    return http.get(userService + "/" + userId + "/favorites");
}

export const setUnsetUserLike = (resourceId: number, userId: number) => {
    return http.post(userService + "/" + userId + "/resource/" + resourceId + "/likes");
}

export const getPermissions = (userId: number) => {
    return http.get(userService + "/" + userId + "/permissions");
}

