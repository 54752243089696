import { useEffect, useState } from "react";
import ProductsDataService from "../../../services/products.service";
import IProductData from "../../../types/product.type";
import IProductProblemData from "../../../types/productProblems.type";
import { Col, Input, Row, Select } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import axios from "axios";
import { getDeviceUsageRiskHazardDetails, getInvestigationDetailsByProblemSourceReasonProductFamily, getProblemsByproductFamily, getProblemSourceReasonsByproductFamily, getProductsByCatalog } from "../../../services/ccm.service";
import { useUserContext } from "../authentication/userContext";
import IError from "../../../types/error.type ";
import CustomAlert from "../../reusableComponents/Alert";
import CircularProgress from '@mui/material/CircularProgress';

type Props = {};

// type State = {
//   products: Array<IProductData>,
//   productProblems: Array<IProductProblemData>,
//   currentIndex: number,
//   selectedProduct: string,
//   selectedReportedProblem1: string
//   requiredReportedProblems1: Array<String>
//   requiredReportedProblems2: Array<string>
// };


const columns: ColumnsType<InvestigationDetail> = [
  {
    title: 'PRE Guidance',
    dataIndex: 'preguidance',
    key: 'preguidance',
    colSpan: 1,
  },
  {
    title: 'Investigation Number',
    dataIndex: 'investigationNumber',
    key: 'investigationNumber',
    colSpan: 1,
  },
  {
    title: 'Investigation Status',
    dataIndex: 'investigationStatus',
    key: 'investigationStatus',
    colSpan: 1,
  },
  {
    title: 'Investigation Rationale',
    dataIndex: 'investigationRationale',
    key: 'rationale',
    colSpan: 1,
  }
]

// const columns: ColumnsType<Item> = [
// {      
//   title: 'ECT',
//   children: [
//     {
//       title: 'Device Use at the Time of the Event',
//       dataIndex: 'deviceUse',
//       key: 'deviceUse',
//       colSpan: 1,
//     },
//     {
//       title: 'System Requirement',
//       dataIndex: 'systemRequirement',
//       key: 'systemRequirement',
//       colSpan: 1,
//     },
//     {
//       title: 'Risk Profile',
//       dataIndex: 'riskProfile',
//       key: 'riskProfile',
//       colSpan: 1,
//     },
//     {
//       title: 'Potential Severity',
//       dataIndex: 'potentialSeverity',
//       key: 'potentialSeverity',
//       colSpan: 1,
//     },

//     {
//       title: 'Risk File Reference',
//       dataIndex: 'riskFileReference',
//       key: 'riskFileReference',
//       colSpan: 1,
//     },
//     {
//       title: 'Is Potentially Reportable Event',
//       dataIndex: 'isPotentiallyReportableEvent',
//       key: 'isPotentiallyReportableEvent',
//       colSpan: 1,
//     }
//   ],
// },
// {
//   title: 'ICT',
//   children: [
//     {
//       title: 'PRE Guidance',
//       dataIndex: 'preGuidance',
//       key: 'preGuidance',
//       colSpan: 1,
//     },
//     {
//       title: 'Investigation Number',
//       dataIndex: 'investigationNumber',
//       key: 'investigationNumber',
//       colSpan: 1,
//     },
//     {
//       title: 'Investigation Status',
//       dataIndex: 'investigationStatus',
//       key: 'investigationStatus',
//       colSpan: 1,
//     },
//     {
//       title: 'Rationale',
//       dataIndex: 'rationale',
//       key: 'rationale',
//       colSpan: 1,
//     }
//   ],
// },
// ];

// const data: Item[] = [
//   {
//     key: '1',
//     deviceUse: '',
//     systemRequirement: '',
//     riskProfile: '',
//     potentialSeverity: '',
//     isPotentiallyReportableEvent: '',
//     riskFileReference: '',
//     preGuidance: '',
//     investigationNumber: '',
//     investigationStatus:'',
//     rationale:'',
//   },
//   // Add more data...
// ];

interface Problem {
  id: number;
  reportedProblemLevel1: string;
  reportedProblemLevel2: string;
  reportedProblemLevel3: string;
}

interface DeviceUsageRiskHazardDetails {
  id: number;
  deviceUseAtTheTimeOfTheEvent: string;
  systemRequirement: string;
  riskProfile: string;
  hazard: string;
  hazardCategory: string;
  potentialSeverity: string;
  isPotentiallyReportableEvent: string;
}

interface ProblemSourceReason {
  id: number;
  problemSource: string;
  problemReason: string;
}

interface InvestigationDetail {
  id: number;
  preguidance: string;
  investigationNumber: string;
  investigationStatus: string;
  investigationRationale: string;
}

// interface Product {
//   value: string
// }


const ComplainCodesDiscovery = () => {
  const [catalogItemId, setCatalogItemId] = useState<string>('');
  const [products, setProducts] = useState<string[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const [problems, setProblems] = useState<Problem[]>([]);
  const [selectedProblem1, setSelectedProblem1] = useState<string>('');
  const [uniqueReportedProblemsLevel1, setUniqueReportedProblemsLevel1] = useState<string[]>([]);
  const [problemL2s, setProblemL2s] = useState<Problem[]>([]);
  const [isRpcLoading, setIsRpcLoading] = useState<boolean>(false);
  const [isProductsLoaded, setIsProductsLoaded] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError ] = useState<IError>();
  const [isDeviceInfoLoading, setIsDeviceInfoLoading] = useState<boolean>(false);

  const [problemL3s, setProblemL3s] = useState<Problem[]>([]);
  const [selectedProblem2, setSelectedProblem2] = useState<string>('');
  const [uniqueReportedProblemsLevel2, setUniqueReportedProblemsLevel2] = useState<string[]>([]);
  const [selectedProblem3, setSelectedProblem3] = useState<string>('');
  const [deviceUsageHazardDetails, setDeviceUsageHazardDetails] = useState<DeviceUsageRiskHazardDetails[]>([]);
  const [uniqueDeviceUsage, setUniqueDeviceUsage] = useState<string[]>([]);
  const [selectedDeviceUsage, setSelectedDeviceUsage] = useState<string>('');
  const [systemRequirements, setSystemRequirements] = useState<string[]>([]);
  const [selectedSystemLevelReq, setSelectedSystemLevelReq] = useState<string>('');
  const [hazards, setHazards] = useState<string[]>([]);
  const [riskProfiles, setRiskProfiles] = useState<string[]>([]);
  const [selectedRiskProfile, setSelectedRiskProfile] = useState<string>('');
  const [selectedHazard, setSelectedHazard] = useState<string>('');
  const [hazardCategories, setHazardCategories] = useState<string[]>([]);
  const [selectedHazardCategory, setSelectedHazardCategory] = useState<string>('');
  const [potentialSeverities, setPotentialSeverities] = useState<string[]>([]);
  const [selectedPotentialSeverity, setSelectedPotentialSeverity] = useState<string>('');
  const [pres, setPres] = useState<string[]>([]);
  const [selectedPre, setselectedPre] = useState<string>('');
  const [problemSourceReasons, setProblemSourceReasons] = useState<ProblemSourceReason[]>([]);
  const [selectedProblemSource, setSelectedProblemSource] = useState<string>('');
  const [uniqueProblemSources, setUniqueProblemSources] = useState<string[]>([]);
  const [problemReasons, setProblemReasons] = useState<string[]>([]);
  const [selectedProblemReason, setSelectedProblemReason] = useState<string>('');
  const [investigationDetails, setInvestigationDetails] = useState<InvestigationDetail[]>([]);
  const [uniqueInvestigationRationales, setUniqueInvestigationRationales] = useState<string[]>([]);
  const [uniqueInvestigationStatuses, setUniqueInvestigationStatuses] = useState<string[]>([]);
  const { userId } = useUserContext();
  const [isProblemSrcLoading, setIsProblemSrcLoading] = useState<boolean>(false);
  const [isIctLoading, setIsIctLoading] = useState<boolean>(false);



  const handleCatalogItemIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCatalogItemId(event.target.value);
    setSelectedProduct('');
    setProducts([]);
  };

  // useEffect(() => {
  //   getProblemsByproductFamily(); 
  // }, [selectedProduct]); 
  useEffect(() => {

    if (userId === null || userId === 0) {
      
      window.location.href = '/';
    } 
  }, [])

  const handleProdFamilyChange = (value: string) => {
    console.log(value);
    setSelectedProduct(value);
    setIsRpcLoading(true);
    setIsProductsLoaded(false);
   
    getProblems(value);
  }

  const handleProblem1Change = (value: string) => {
    console.log('problem1 ' + value);
    setSelectedProblem1(value);
    const uniqueProblemL2s = (problems
      .filter(data =>
      (
        data.reportedProblemLevel1.match(value))
      ));
    // .map(problem => problem.reportedProblemLevel2);
    setProblemL2s(uniqueProblemL2s);
    setUniqueReportedProblemsLevel2(Array.from(new Set(uniqueProblemL2s.map((item: Problem) => item.reportedProblemLevel2))));
  }

  const escapeRegExp = (text: string) => {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  };

  const handleProblem2Change = (value: string) => {
    console.log('problem2 ' + value);
    setSelectedProblem2(value);

    const escapedInput = escapeRegExp(value);
    const regex = new RegExp(escapedInput, 'i');

    const uniqueProblemL3s = (problemL2s
      .filter(data =>
      (
        regex.test(data.reportedProblemLevel2))
      ));
    // .map(problem => problem.reportedProblemLevel3);
    setProblemL3s(uniqueProblemL3s);
  }

  const handleproblem3Change = (value: string) => {
    console.log('problem3 ' + value);
    setSelectedProblem3(value);
    setIsDeviceInfoLoading(true);
    cleanHazardPsprIct();
    getDetails(value);
  }
  // const retrieveUniqueProblemsLevel1 = () =>  {
  //   const uniqueProblemsLevel1 = Array.from(new Set(problems.map(item => item.reportedProblemLevel1)));
  //   console.log(uniqueProblemsLevel1);
  //   setUniqueReportedProblemsLevel1(uniqueProblemsLevel1);
  // }

  const handleTabKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') {
      getProductsBasedCatalog();
    }
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      getProductsBasedCatalog();
    }
  };

  
  const handleProductLoad = () => {
      getProductsBasedCatalog();
  };

  const getProductsBasedCatalog = () => {

    getProductsByCatalog(catalogItemId)
      .then(async function (response) {
        if (response.status === 200) {
          setProducts(response.data);
          setIsProductsLoaded(true);
          setIsError(false); 
        } 
      })
      .catch(async function (response)  {
        console.error('Error calling API:', response);
        let errorResponse = response.response !== undefined ? response.response : null;
        if (errorResponse != null) {
          setIsError(true);  
          var status = errorResponse.status.toString();
          if (status.startsWith('5')) {
            setError(errorResponse.data.error);
          } else {
            setError(errorResponse.data.message);
          }
          
        } 
        // else {
          // setError(response.response.data.error);
          // alert(response.response.data.error);
        // }
        setIsProductsLoaded(true);
      });
  };

  const getProblems = (value: string) => {
    // const apiUrl = 'http://localhost:8080/ccm/lookup/api/complaintCodes'; 
    getProblemsByproductFamily(value)
      .then((response) => {
        setProblems(response.data);
        setUniqueReportedProblemsLevel1(Array.from(new Set(response.data.map((item: Problem) => item.reportedProblemLevel1))));
        setIsRpcLoading(false);
      })
      .catch((error) => {
        console.error('Error calling API:', error);
        setIsRpcLoading(false);
      });
  };

  const getProblemSourceReasons = (value: string) => {
    // const apiUrl = 'http://localhost:8080/ccm/lookup/api/problemSourceReasons'; 

    getProblemSourceReasonsByproductFamily(value)
      .then((response) => {
        setProblemSourceReasons(response.data);
        setUniqueProblemSources(Array.from(new Set(response.data.map((item: ProblemSourceReason) => item.problemSource))));
        setIsProblemSrcLoading(false);
      })
      .catch((error) => {
        console.error('Error calling API:', error);
        setIsProblemSrcLoading(false);
      });
  };

  const getInvestigationDetails = (value: string) => {
    // const apiUrl = 'http://localhost:8080/ccm/lookup/api/investigationDetails'; 

    getInvestigationDetailsByProblemSourceReasonProductFamily(selectedProblemSource, value, selectedProduct)
      .then((response) => {
        setInvestigationDetails(response.data);
        // setUniqueInvestigationRationales(Array.from(new Set(response.data.map((item:InvestigationDetail) => item.investigationRationale))));
        // setUniqueInvestigationStatuses(Array.from(new Set(response.data.map((item:InvestigationDetail) => item.investigationStatus))));
        setIsIctLoading(false);
      })
      .catch((error) => {
        console.error('Error calling API:', error);
        setIsIctLoading(false);
      });
  };

  const filteredData = investigationDetails.filter(
    (item, index, self) =>
      // index === self.findIndex((t) => t.investigationRationale === item.investigationRationale)
      index === self.findIndex((t) => (t.investigationRationale === item.investigationRationale) ||
        (t.investigationNumber === item.investigationNumber) ||
        (t.investigationStatus === item.investigationStatus) ||
        (t.preguidance === item.preguidance)
      )
  );

  const getDetails = (value: string) => {

    getDeviceUsageRiskHazardDetails(selectedProblem1, selectedProblem2, value, selectedProduct)
      .then((response) => {
        setDeviceUsageHazardDetails(response.data);
        setUniqueDeviceUsage(Array.from(new Set(response.data.map((item: DeviceUsageRiskHazardDetails) => item.deviceUseAtTheTimeOfTheEvent))));
        setIsDeviceInfoLoading(false);
      })
      .catch((error) => {
        console.error('Error calling deviceUsageRiskHazard API:', error)
        setIsDeviceInfoLoading(false);
      });
  }

  const handleDeviceUsageChange = (value: string) => {
    console.log('device usage ' + value);
    setSelectedDeviceUsage(value);

    const escapedInput = escapeRegExp(value);
    const regex = new RegExp(escapedInput, 'i');

    const uniqueSystemRequirements = (deviceUsageHazardDetails.filter(data => (
      regex.test(data.deviceUseAtTheTimeOfTheEvent))
    ));

    setSystemRequirements(Array.from(new Set(uniqueSystemRequirements.map((item: DeviceUsageRiskHazardDetails) => item.systemRequirement))));
  }

  const handleSystemLevelRequirementChange = (value: string) => {
    console.log("system level requirement " + value);
    setSelectedSystemLevelReq(value);
    const escapedInput = escapeRegExp(value);
    const regex = new RegExp(escapedInput, 'i');

    const uniqueRiskProfiles = (deviceUsageHazardDetails.filter(data => (
      regex.test(data.systemRequirement))
    ));

    setRiskProfiles(Array.from(new Set(uniqueRiskProfiles.map((item: DeviceUsageRiskHazardDetails) => item.riskProfile))))
  }

  const handleRiskProfileChange = (value: string) => {
    console.log("risk profile " + value);
    setSelectedRiskProfile(value);

    const escapedInput = escapeRegExp(value);
    const regex = new RegExp(escapedInput, 'i');

    const uniqueHazards = (deviceUsageHazardDetails.filter(data => (
      regex.test(data.riskProfile))
    ));

    setHazards(Array.from(new Set(uniqueHazards.map((item: DeviceUsageRiskHazardDetails) => item.hazard))))
  }

  const handleHazardChange = (value: string) => {
    console.log("hazard " + value);
    setSelectedHazard(value);

    const escapedInput = escapeRegExp(value);
    const regex = new RegExp(escapedInput, 'i');

    const uniqueHazards = (deviceUsageHazardDetails.filter(data => (
      regex.test(data.hazard))
    ));

    setHazardCategories(Array.from(new Set(uniqueHazards.map((item: DeviceUsageRiskHazardDetails) => item.hazardCategory))))
  }

  const handleHazardCategoryChange = (value: string) => {
    console.log("hazard category" + value);
    setSelectedHazardCategory(value);

    const escapedInput = escapeRegExp(value);
    const regex = new RegExp(escapedInput, 'i');

    const uniqueHazardCategories = (deviceUsageHazardDetails.filter(data => (
      regex.test(data.hazardCategory))
    ));

    setPotentialSeverities(Array.from(new Set(uniqueHazardCategories.map((item: DeviceUsageRiskHazardDetails) => item.potentialSeverity))))
  }

  const handlePotentialSeverityChange = (value: string) => {
    console.log("potential severity " + value);
    setSelectedPotentialSeverity(value);

    const escapedInput = escapeRegExp(value);
    const regex = new RegExp(escapedInput, 'i');

    const uniquePotentialSeverities = (deviceUsageHazardDetails.filter(data => (
      regex.test(data.potentialSeverity))
    ));

    setPres(Array.from(new Set(uniquePotentialSeverities.map((item: DeviceUsageRiskHazardDetails) => item.isPotentiallyReportableEvent))))
  }


  const handlePreChange = (value: string) => {
    console.log("is pre  " + value);
    setselectedPre(value);
    setIsProblemSrcLoading(true);
    cleanPspr();
    cleanIct();
    getProblemSourceReasons(selectedProduct);
  }

  const handleProblemSourceChange = (value: string) => {
    console.log("problem source " + value);
    setSelectedProblemSource(value);
    const escapedInput = escapeRegExp(value);
    const regex = new RegExp(escapedInput, 'i');

    const uniqueProblemSources = (problemSourceReasons.filter(data => (
      regex.test(data.problemSource))
    ));

    setProblemReasons(Array.from(new Set(uniqueProblemSources.map((item: ProblemSourceReason) => item.problemReason))));
  }

  const handleProblemReasonChange = (value: string) => {
    console.log("problem reason " + value);
    setSelectedProblemReason(value);
    setIsIctLoading(true);
    getInvestigationDetails(value);
  }

  const handleClean = () => {
    setCatalogItemId('');
    setSelectedProduct('');
    setProducts([]);
    setSelectedProblem1('');
    setSelectedProblem2('');
    setSelectedProblem3('');
    setUniqueReportedProblemsLevel1([]);
    setUniqueReportedProblemsLevel2([]);
    setProblemL3s([]);
    cleanHazardPsprIct();
  }

  const cleanHazardPsprIct = () => {
    setSelectedDeviceUsage('');
    setSelectedSystemLevelReq('');
    setSelectedRiskProfile('');
    setSelectedHazard('');
    setSelectedHazardCategory('');
    setSelectedPotentialSeverity('');
    setselectedPre('');
    setUniqueDeviceUsage([]);
    setSystemRequirements([]);
    setRiskProfiles([]);
    setHazards([]);
    setHazardCategories([]);
    setPotentialSeverities([]);
    setPres([]);
    cleanPspr();
    cleanIct();
  }

  const cleanPspr = () => {
    setSelectedProblemSource('');
    setSelectedProblemReason('');
    setUniqueProblemSources([]);
    setProblemReasons([]);
  }

  const cleanIct = () => {
    setInvestigationDetails([]);
  }


  return (
    <div>
      <h1 className='page-header'>  Discovery</h1>
      
    <div className=" d-flex align-items-end justify-content-end">
        {/* <div> */}
            <button className="clean" onClick={() =>handleClean()}>Reset</button>
        {/* </div> */}
    </div>

      <div>
        <Row gutter={16} style={{ backgroundColor: '#4c5e90', width: '100%', height: 25, color: 'white', textAlign: 'center', fontSize: '20px', fontWeight: 600 }}>
          <Col span={24} className='content-title'> Product Hierarchy</Col>
        </Row>
        <div className='mt-3'>
          <Row>
            <Col span={3}>
              <label htmlFor='dropdown' className='content'>Catalog Item Id</label>
            </Col>
            <Col span={8}>
              <Input type='text' style={{ width: '80%' }} onChange={handleCatalogItemIdChange} value={catalogItemId} onKeyDown={handleTabKeyPress} onKeyPress={handleEnterKeyPress}/><br />
              {/* <div className="notes">
                * Enter CatalogItemId and Press Enter/Tab key
              </div> */}
              {isError&&<CustomAlert/>}

            </Col>
            <Col span={3}>
              <label htmlFor='dropdown' className='content'>Product Family</label>
            </Col>
            <Col span={8} >
              <Select showSearch onChange={handleProdFamilyChange} value={selectedProduct} disabled={isRpcLoading}
                placeholder='Select Product Family' onClick={handleProductLoad} style={{ width: '80%' }} className={isProductsLoaded ? 'highlighted' : ''}

              // options={[
              //   { value: 'Azurion', label: 'Azurion' },
              //   { value: 'Allura', label: 'Allura' }         // ]}
              >

                {/* <CustomDropdown
        disabled={isLoading}
        options={products}
        onChange={(event) => handleProdFamilyChange(event.target.value)}
        placeholder="Select a Product"
        backgroundColor="white" // Set the background color to white
      /> */}

                {products.map((item: string, index: number) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>
        <div className='mt-3'>
          <Row gutter={16} style={{ backgroundColor: '#4c5e90', width: '100%', height: 25, color: 'white', textAlign: 'center', fontSize: '20px', fontWeight: 500 }}>
            <Col span={24} className='content-title'>Reported Problem Codes</Col>
          </Row>
        </div>
        <div className='mt-3'>
          <Row gutter={16}>
            <Col span={3}>
              <label htmlFor='dropdown' className='content'>Reported Problem1</label>
            </Col>
            <Col span={8}>
              <Select showSearch placeholder='Select Reported Problem1' onChange={handleProblem1Change} value={selectedProblem1} className='dropdowns' >
                {/* {
                      problems.map((data,index) => (
                        <option key={index} value={data.reportedProblemLevel1}/>
                      ))
                     }  */}
                {!isRpcLoading &&
                  uniqueReportedProblemsLevel1.map((data, index) => (
                    <option key={index} value={data} />
                  ))
                }
              </Select>
              {isRpcLoading && (
                <div style={{ position: 'absolute', top: '50%', left: '30%', transform: 'translate(-50%, -50%)' }}>
                  <p><CircularProgress style={{ color: "grey" }} size="1.5rem" /></p>
                </div>
              )}
            </Col>
            <Col span={3}>
              <label htmlFor='dropdown' className='content'>Reported Problem2</label>
            </Col>
            <Col span={8}>
              <Select showSearch placeholder='Select Reported Problem2' onChange={handleProblem2Change} value={selectedProblem2} className='dropdowns'>
                {
                  uniqueReportedProblemsLevel2.map((data, index) => (
                    <option key={index} value={data} />
                  ))
                }
              </Select>
            </Col>
          </Row>
        </div>
        <div className='mt-3'>
          <Row gutter={16}>
            <Col span={6} />
            <Col span={3}>
              <label htmlFor='dropdown' className='content'>Reported Problem3</label>
            </Col>
            <Col span={10}>
              <Select showSearch placeholder='Select Reported Problem3' onChange={handleproblem3Change} value={selectedProblem3} className='dropdowns' >
                {
                  problemL3s.map((data, index) => (
                    <option key={index} value={data.reportedProblemLevel3} />
                  ))
                }
              </Select>
            </Col>
          </Row>
        </div>

        <div className='mt-3'>
          <Row gutter={16} style={{ backgroundColor: '#4c5e90', width: '100%', height: 25, color: 'white', textAlign: 'center', fontSize: '20px', fontWeight: 600 }}>
            <Col span={24} className='content-title'>Hazard Category</Col>
          </Row>
        </div>
        <div className='mt-3'>
          <Row gutter={16}>
            <Col span={3}>
              <label htmlFor='dropdow' className='content'>Device Use at Time of Event</label>
            </Col>
            <Col span={8}>
              <Select showSearch placeholder='Select Device Use' onChange={handleDeviceUsageChange} value={selectedDeviceUsage} className='dropdowns' >
                {!isDeviceInfoLoading &&
                  uniqueDeviceUsage.map((data, index) => (
                    <option key={index} value={data} />
                  ))
                }
              </Select>
              {isDeviceInfoLoading && (
                <div style={{ position: 'absolute', top: '50%', left: '30%', transform: 'translate(-50%, -50%)' }}>
                  <p><CircularProgress style={{ color: "grey" }} size="1.5rem" /></p>
                </div>
              )}
            </Col>
            <Col span={3}>
              <label htmlFor='dropdown' className='content'>System level Requirement</label>
            </Col>
            <Col span={8}>
              <Select showSearch placeholder='Select System level Requirement' onChange={handleSystemLevelRequirementChange} value={selectedSystemLevelReq} className='dropdowns' >
                {
                  systemRequirements.map((data, index) => (
                    <option key={index} value={data} />
                  ))
                }
              </Select>
            </Col>
          </Row>
        </div>

        <div className='mt-3'>
          <Row gutter={16}>
            <Col span={3}>
              <label htmlFor='dropdow' className='content'>Risk Profile</label>
            </Col>
            <Col span={8}>
              <Select showSearch placeholder='Select Risk Profile' onChange={handleRiskProfileChange} value={selectedRiskProfile} className='dropdowns' >
                {
                  riskProfiles.map((data, index) => (
                    <option key={index} value={data} />
                  ))
                }

              </Select>
            </Col>
            <Col span={3}>
              <label htmlFor='dropdown' className='content'>Hazard</label>
            </Col>
            <Col span={8}>
              <Select showSearch placeholder='Select Hazard' onChange={handleHazardChange} value={selectedHazard} className='dropdowns' >
                {
                  hazards.map((data, index) => (
                    <option key={index} value={data} />
                  ))
                }
              </Select>
            </Col>
          </Row>
        </div>

        <div className='mt-3'>
          <Row gutter={16}>
            <Col span={3}>
              <label htmlFor='dropdow' className='content'>Hazard Category</label>
            </Col>
            <Col span={8}>
              <Select showSearch placeholder='Select Hazard Category' onChange={handleHazardCategoryChange} value={selectedHazardCategory} className='dropdowns' >
                {
                  hazardCategories.map((data, index) => (
                    <option key={index} value={data} />
                  ))
                }

              </Select>
            </Col>
            <Col span={3}>
              <label htmlFor='dropdown' className='content'>Potential Severity</label>
            </Col>
            <Col span={8}>
              <Select showSearch placeholder='Select Potential Severity' onChange={handlePotentialSeverityChange} value={selectedPotentialSeverity} className='dropdowns' >
                {
                  potentialSeverities.map((data, index) => (
                    <option key={index} value={data} />
                  ))
                }
              </Select>
            </Col>
          </Row>
        </div>

        <div className='mt-3'>
          <Row gutter={16}>
            <Col span={6} />
            <Col span={3}>
              <label htmlFor='dropdown' className='content'>PRE</label>
            </Col>
            <Col span={10}>
              <Select showSearch placeholder='Select PRE' onChange={handlePreChange} value={selectedPre} className='dropdowns' >
                {
                  pres.map((data, index) => (
                    <option key={index} value={data} />
                  ))
                }
              </Select>
            </Col>
          </Row>
        </div>


        <div className='mt-3'>
          <Row gutter={16} style={{ backgroundColor: '#4c5e90', width: '100%', height: 25, color: 'white', textAlign: 'center', fontSize: '20px', fontWeight: 500 }}>
            <Col span={24} className='content-title'>Problem Source/Reason Codes</Col>
          </Row>
        </div>
        <div className='mt-3'>
          <Row gutter={16}>
            <Col span={3}>
              <label htmlFor='dropdown' className='content'>Problem Source</label>
            </Col>
            <Col span={8}>
              <Select showSearch placeholder="Select Problem Source" onChange={handleProblemSourceChange} value={selectedProblemSource} className='dropdowns' >
                {!isProblemSrcLoading && 
                  uniqueProblemSources.map((data, index) => (
                    <option key={index} value={data} />
                  ))
                }
              </Select>
              {isProblemSrcLoading && (
                <div style={{ position: 'absolute', top: '50%', left: '30%', transform: 'translate(-50%, -50%)' }}>
                  <p><CircularProgress style={{ color: "grey" }} size="1.5rem" /></p>
                </div>
              )}
            </Col>
            <Col span={3}>
              <label htmlFor='dropdown' className='content'>Problem Reason</label>
            </Col>
            <Col span={8}>
              <Select showSearch placeholder="Select Problem Reason" onChange={handleProblemReasonChange} value={selectedProblemReason} className='dropdowns' >
                {
                  problemReasons.map((data, index) => (
                    <option key={index} value={data} />
                  ))
                }
              </Select>
            </Col>
          </Row>
        </div>

        <div className='mt-3'>
          <Row gutter={16} style={{ backgroundColor: '#4c5e90', width: '100%', height: 25, color: 'white', textAlign: 'center', fontWeight: 500 }}>
            <Col span={24} className='content-title'>Investigation Codes</Col>
          </Row>
          <div className='mt-3'>
          <div style={{ position: 'relative' }}>
          {!isIctLoading &&  <Table columns={columns} dataSource={filteredData} bordered />}
          {isIctLoading && (
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -30%)' }}>
                  <p><CircularProgress style={{ color: "grey" }} size="2rem" /></p>
                </div>
              )}
              </div>
          </div>


        </div>

      </div>



    </div>
  );

}

interface CustomDropdownProps {
  disabled: boolean;
  options: string[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  placeholder: string;
  backgroundColor: string;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  disabled,
  options,
  onChange,
  placeholder,
  backgroundColor,
}) => {
  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        backgroundColor: disabled ? backgroundColor : '',
      }}
    >
      <Select
        showSearch
        disabled={disabled}
        onChange={onChange}
        style={{
          width: '650%',
          padding: '8px',
          borderRadius: '4px',
          outline: 'none',
          border: '1px solid #ccc',
          backgroundColor: disabled ? 'lightgray' : '',
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
      >
        <option value="">{placeholder}</option>
        {options.map((item: string, index: number) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default ComplainCodesDiscovery;
